import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import { Button } from '../../components';
import CalendarTray from './calendar-tray';

import './css.css';
import css from './calendar.module.css';

export default function CommunityCalendar() {
  const [activeEvent, setActiveEvent] = useState(false);
  const [domText, setDomText] = useState('');
  const calendar = useRef(null);

  const currentDate = new Date();
  const monthShort = currentDate.toLocaleString('default', { month: 'short' });
  const monthLong = currentDate.toLocaleString('default', { month: 'long' });
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  // current week of the month
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const week = Math.ceil((currentDate.getDate() + startOfMonth.getDay()) / 7);

  // Calculate the number of days in the current month
  const daysInMonth = new Date(year, currentDate.getMonth() + 1, 0).getDate();
  const monthRange = `${monthShort} 1, ${year} - ${monthShort} ${daysInMonth}, ${year}`;

  const eventsData = [
    {
      title: 'All Day Event',
      start: '2025-01-10',
    },
    {
      title: 'Long Event',
      start: '2018-01-07',
      end: '2025-01-10',
    },
    {
      groupId: 999,
      title: 'Repeating Event',
      start: '2025-01-10T16:00:00'
    },
    {
      groupId: 999,
      title: 'Repeating Event',
      start: '2025-01-16T16:00:00'
    },
    {
      title: 'Conference',
      start: '2025-01-11',
      end: '2025-01-13'
    },
    {
      title: 'Meeting',
      start: '2025-01-12T10:30:00',
      end: '2025-01-12T12:30:00'
    },
    {
      title: 'Lunch',
      start: '2025-01-12T12:00:00'
    },
    {
      title: 'Meeting',
      start: '2025-01-12T14:30:00'
    },
    {
      title: 'Happy Hour',
      start: '2025-01-12T17:30:00'
    },
    {
      title: 'Dinner',
      start: '2025-01-12T20:00:00'
    },
    {
      title: 'Birthday Party',
      start: '2025-01-13T07:00:00',
    },
    {
      title: 'Click for Google',
      url: 'http://google.com/',
      start: '2025-01-28'
    }
  ];

  useEffect( () => {
    if(calendar.current) {
      let calendar1 = new Calendar(calendar.current, {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin
        ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'title',
          center: 'prev,today,next',
          right: 'dayGridMonth,timeGridWeek,listWeek',
        },
        events: eventsData,
        eventClick: function (info) {
          if (!info?.event?.url) {
            info.jsEvent.preventDefault()
            setActiveEvent(info.event)
          }
        },
      })
      calendar1.render();
    }

    const domElement = document.getElementById('fc-dom-1');
    if (domElement) {
      setDomText(domElement.textContent);

      const observer = new MutationObserver(() => {
        setDomText(domElement.textContent);
      });

      observer.observe(domElement, { childList: true, characterData: true, subtree: true });

      return () => {
        observer.disconnect();
      }
    }
  }, [])

  // useEffect(() => {
  //   const domElement = document.getElementById('fc-dom-1');
  //   if (domElement) {
  //     setDomText(domElement.textContent);
  //   }
  // }, [domText]);
  

  const tray = <CalendarTray onClick={setActiveEvent} activeEvent={activeEvent} />;
  const handleAddEvent = () => {
    setActiveEvent(true);
  };

  const monthBlock = (
    <div className={css.monthBlock}>
      <div className={css.today}>
        <div className={css.mon}>{monthShort}</div>
        <div className={css.day}>{day}</div>
      </div>
      <div className={css.monthInfo}>
        <div className={css.mon}>
          {domText}
        </div>
      </div>
    </div>
  );

  const btnAddEvent = <Button label="+ Add event" onClick={handleAddEvent} />;

  const calendarBlock = (
    <div className={css.calendarBlock}>
      {monthBlock}
      {btnAddEvent}
      <div id='calendar' className={css.calendarEl} ref={calendar} />
    </div>
    
  );

  return (
    <div className={css.calendar}>
      {calendarBlock}

      {activeEvent && tray}
    </div>
  );
}
