import React, { useContext } from 'react'
import { Dropdown } from 'primereact/dropdown'

import AuthContext from '../../../context/auth-context'

import Icon from '../../icon/icon'
import css from './menu-settings.module.css'

export default function MenuSettings({ logout, ...rest }) {
  const { userData } = useContext(AuthContext)

  const avatar = userData?.first_name[0] + userData?.last_name[0]
  const name = userData?.first_name + ' ' + userData?.last_name

  const header = (
    <div className={css.header}>
      <div className={css.avatar}>{avatar}</div>
      <div className={css.user}>
        <p>{name}</p>
        <p>{userData?.email}</p>
      </div>
    </div>
  )

  const content = (
    <div className={css.content}>
      <Dropdown {...rest} />
    </div>
  )

  const footer = (
    <div className={css.footer}>
      <div className={css.logout} onClick={logout}>
        <Icon icon="Logout" />
        Sign out
      </div>
    </div>
  )

  return (
    <div className={css.menuSettings}>
      {header}
      {content}
      {footer}
    </div>
  )
}
