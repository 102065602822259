export const TOP_LEVEL_PAGES = [
  {
    path: "home",
    display: "Home",
    icon: "Calendar",
    visibility: ['is_guardian', 'is_vet', 'is_barn_manager']
  },
  {
    path: "calendar",
    display: "Calendar",
    icon: "Calendar",
    visibility: ['is_guardian', 'is_vet', 'is_barn_manager']
  },
  {
    path: "stalls",
    display: "Stalls",
    icon: "Stall",
    visibility: ['is_guardian', 'is_vet', 'is_barn_manager']
  },
  {
    path: "horses",
    display: "Horses",
    icon: "Horse2",
    visibility: ['is_guardian', 'is_vet', 'is_barn_manager']
  },
  {
    path: "users",
    display: "Users",
    icon: "User2",
    visibility: ['is_barn_manager']
  },
  {
    path: "trainers",
    display: "Trainers",
    icon: "User2",
    visibility: ['is_barn_manager']
  },
];