import React, { useContext } from "react";

import "./not-found.scss";
import AuthContext from "../../context/auth-context";
import { useNavigate } from "react-router-dom";

export function NotFound() {
  const {logout} = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  }

  return (
    <div className="not-found-container">
      <h1>404 Not Found</h1>
      <a href="/">Back</a>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default NotFound;