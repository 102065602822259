import React from 'react'
import { NavLink } from 'react-router-dom'

import Icon from '../icon/icon'

import css from './logo.module.css'

export default function Logo({
  showLogoTitle = true
}) {
  return (
    <NavLink to='./' className={css.logo}>
      <Icon icon="NavLogo" />
      {showLogoTitle && <Icon icon="NavLogoHW" />}
    </NavLink>
  )
}
