import React from "react";

import css from "./global-banner.module.css";
import Icon from "../icon/icon";

export default function GlobalBanner({ onClick }) {
  const content = (
    <div>
      <p>banner here</p>
    </div>
  );

  return (
    <div
      id="globalBanner"
      className={`${css.globalBanner}`}
    >
      {content}

      <div className={css.close} onClick={() => onClick(false)}>
        <Icon icon="X" />
      </div>
    </div>
  );
};
