import React from "react";
import { Route } from "react-router-dom";

import ProtectedLayout from "../layouts/protected";

import Main from "../components/main/main";
import Stalls from "../pages/stalls/stalls";
import Horses from "../pages/horses/horses";
import Users from "../pages/users/users";
import Onboarding from "../pages/onboarding/onboarding";
import MobileStream from "../pages/mobile-stream";
import CommunityCalendar from "../pages/calendar/calendar";
import Home from "../pages/home/home";
import Trainers from "../pages/trainers/trainers";

const AdminRouter = (
  <Route element={<ProtectedLayout />}>
    <Route path="/" element={<Main />}>
      <Route path="home" element={<Home />} />
      <Route path="stalls" element={<Stalls />} />
      <Route path="calendar" element={<CommunityCalendar />} />
      <Route path="horses" element={<Horses />} />
      <Route path="users" element={<Users />} />
      <Route path="trainers" element={<Trainers />} />
      <Route path="onboarding" element={<Onboarding />} />
    </Route>
    <Route path="/mobile/streaming/:cameraId" element={<MobileStream />} />
  </Route>
);

export default AdminRouter;