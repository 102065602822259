import React from 'react'

import { useQuery } from '../../hooks'
import StallSection from '../stalls/stall-section/stall-section'

import css from './home.module.css'

export default function Home() {
  const { data } = useQuery(`/stalls`)

  const stalls = (
    <div className={css.stalls}>
      <StallSection data={data?.data} />
    </div>
  )

  const dashboard = (
    <div className={css.dashboard}>
      <div className={css.inner}>
        <p>dashboard block</p>
      </div>
    </div>
  )

  return (
    <div className={css.home}>
      {dashboard}
      {stalls}
    </div>
  )
}
