import React, { useEffect, useRef, useState } from 'react'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import Sidebar from '../../components/sidebar/sidebar'
import Button from '../../components/button/button'

import css from './trainers.module.css'

export default function Trainers() {
  const [showSidebar, setShowSidebar] = useState(false)
  const [activeEvent, setActiveEvent] = useState(false)
  const [domText, setDomText] = useState('')

  const calendar = useRef(null)
  const calendarList = useRef(null)

  const currentDate = new Date()
  const monthShort = currentDate.toLocaleString('default', { month: 'short' })
  // const monthLong = currentDate.toLocaleString('default', { month: 'long' })
  const day = currentDate.getDate()
  // const year = currentDate.getFullYear()

  // current week of the month
  // const startOfMonth = new Date(
  //   currentDate.getFullYear(),
  //   currentDate.getMonth(),
  //   1,
  // )
  // const week = Math.ceil((currentDate.getDate() + startOfMonth.getDay()) / 7)

  // Calculate the number of days in the current month
  // const daysInMonth = new Date(year, currentDate.getMonth() + 1, 0).getDate()
  // const monthRange = `${monthShort} 1, ${year} - ${monthShort} ${daysInMonth}, ${year}`

  const eventsData = [
    {
      title: 'All Day Event',
      start: '2025-01-10',
    },
    {
      title: 'Long Event',
      start: '2018-01-07',
      end: '2025-01-10',
    },
    {
      groupId: 999,
      title: 'Repeating Event',
      start: '2025-01-23T16:00:00',
    },
    {
      groupId: 999,
      title: 'Repeating Event',
      start: '2025-01-16T16:00:00',
    },
    {
      title: 'Conference',
      start: '2025-01-11',
      end: '2025-01-13',
    },
    {
      title: 'Meeting',
      start: '2025-01-12T10:30:00',
      end: '2025-01-12T12:30:00',
    },
    {
      title: 'Lunch',
      start: '2025-01-12T12:00:00',
    },
    {
      title: 'Meeting',
      start: '2025-01-12T14:30:00',
    },
    {
      title: 'Happy Hour',
      start: '2025-01-12T17:30:00',
    },
    {
      title: 'Dinner',
      start: '2025-01-12T20:00:00',
    },
    {
      title: 'Birthday Party',
      start: '2025-01-13T07:00:00',
    },
    {
      title: 'Click for Google',
      url: 'http://google.com/',
      start: '2025-01-28',
    },
  ]

  

  useEffect(() => {
    if (showSidebar) {
      if (calendar.current) {
        let calendar1 = new Calendar(calendar.current, {
          plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
          initialView: 'dayGridMonth',
          headerToolbar: {
            left: 'title',
            center: 'dayGridMonth,timeGridWeek,listWeek',
            right: 'prev,today,next',
          },
          events: eventsData,
          eventClick: function (info) {
            if (!info?.event?.url) {
              info.jsEvent.preventDefault()
              setActiveEvent(info.event)
            }
          },
        })
        
        calendar1.render()
      }

      if (calendarList.current) {
        let calendarList1 = new Calendar(calendarList.current, {
          plugins: [listPlugin],
          initialView: 'listDay',
          events: eventsData,
          headerToolbar: {
            left: 'title',
            center: null,
            right: null
          },
        })

        calendarList1.render()
      }

      const domElement = document.getElementById('fc-dom-1') 
      if (domElement) {
        setDomText(domElement.textContent)

        const observer = new MutationObserver(() => {
          setDomText(domElement.textContent)
        })

        observer.observe(domElement, {
          childList: true,
          characterData: true,
          subtree: true,
        })

        return () => {
          observer.disconnect()
        }
      }
    }
  }, [])

  const name = 'Lana'

  const monthBlock = (
    <div className={css.monthBlock}>
      <div className={css.today}>
        <div className={css.mon}>{monthShort}</div>
        <div className={css.day}>{day}</div>
      </div>
      <div className={css.monthInfo}>
        <div className={css.mon}>{domText}</div>
      </div>
    </div>
  )

  const btnSchedule = (
    <Button
      key="btnSchedule"
      onClick={() => alert('Schedule functionality')}
      label="Schedule training"
    />
  )

  const actions = [btnSchedule]

  const propsSidebar = {
    footerbuttons: actions,
    headertitle: `${name}'s Schedule`,
    onHide: () => setShowSidebar(),
    visible: showSidebar,
  }

  const sidebar = (
    <Sidebar {...propsSidebar}>
      {monthBlock}
      <div id="calendar" className={css.calendar} ref={calendar} />
      <div id="calendarList" className={css.calendar} ref={calendarList} />
    </Sidebar>
  )

  return (
    <div className={css.trainers}>
      <div className={css.cardSection}>
        Trainer cards here
        <Button onClick={() => setShowSidebar(true)}>Open sidebar</Button>
        {sidebar}
      </div>
    </div>
  )
}
