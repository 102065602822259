import React, { useState } from "react";
import { Outlet, useLocation, } from "react-router-dom";

import Header from "../header/header";

import css from "./main.module.css";

const Main = () => {
  const loc = useLocation();
  const [activePage, setActivePage] = useState(loc.pathname.slice(1));

  return (
    <div className={`site ${css.site}`}>
      <Header activePage={activePage} setActivePage={setActivePage} />
      <main className={css.main}>
        <Outlet context={[activePage, setActivePage]} />
      </main>
    </div>
  );
};

export default Main;