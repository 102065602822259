import React from "react";
import { Sidebar as PrSidebar } from "primereact/sidebar";

import Icon from "../icon/icon";

import "./css.css";
import css from "./sidebar.module.css";

const Sidebar = ({
  children,
  headertitle,
  footerbuttons = [],
  ...props
}) => {

  const sidebarContent = ({closeIconRef, hide, children}) => {
    const header = (
      <div className={css.header}>
        {headertitle && <h3>{headertitle}</h3>}
        <div
          ref={closeIconRef}
          onClick={(e) => hide(e)}
          className={css.closeIcon}
        >
          <Icon icon="X" fill="#000000" />
        </div>
      </div>
    )

    const footer = (
      <div className={css.footer}>
        {footerbuttons.map((button, index) => (
          <React.Fragment key={index}>
            {button}
          </React.Fragment>
        ))}
      </div>
    )
    
    return (
      <>
        {header}

        <div className={css.content}>
          {children}
        </div>

        {footerbuttons.length > 0 && footer}
      </>
    )
  }

  return (
    <PrSidebar
      {...props}
      position='right'
      content={({closeIconRef, hide}) => sidebarContent({closeIconRef, hide, children})}
      className={css.sidebar}
    />
  );
};

export default Sidebar;
