/**@jsxImportSource @emotion/react */
import { useState, useRef, useContext, useEffect } from "react";
import CustomDivTable from "../../components/custom-table/custom-div-table";
import { useQuery } from "../../hooks";
import { Toast } from "primereact/toast";
import NavigationActionBar from "../../components/navigation-action-bar/navigation-action-bar";
import EditHorseModal from "../../components/modals/EditHorseModal";
import CustomDivTableActions from "../../components/custom-table/custom-div-table-actions";
import ButtonIcon from "../../components/button-icon/button-icon";
import Icon from "../../components/icon/icon";
import { appColors } from '../../emotion/appColors';
import { css } from "./css";
import { useSearchParams } from "react-router-dom";
import ShowArchiveHorseModal from "./show-archive-horse-modal";


import AuthContext from '../../context/auth-context';

const horseTabs = [
  { label: 'All', url: '/horses?archived=all' },
  { label: 'Active', url: '/horses?archived=active' },
  { label: 'Archived', url: '/horses?archived=archived' },
];

export default function Horses() {
  const toastRef = useRef();
  const MAX_LENGTH = 70;
  const { userData } = useContext(AuthContext);

  const [params, setParams] = useSearchParams();
  
  useEffect(() => {
    if (!params.get('archived')) {
      params.set('archived', 'active');
      setParams(params);
    }
  }, [params, setParams]);

  const [archiveHorseModal, setArchiveHorseModal] = useState(false);
  //const [showAddHorseModal, setShowAddHorseModal] = useState(false);
  const [showEditHorseModal, setShowEditHorseModal] = useState(false);
  
  const activeHorse = useRef(null);

  let horsesUrl = '/horses?orderBy=name';
  // if (!visibility || visibility === 'active') {
  //   horsesUrl += '&archived=false';
  // } else if (visibility === 'archived') {
  //   horsesUrl += '&archived=true';
  // }

  const { data, refetch } = useQuery(horsesUrl);

  useEffect(() => {
    refetch()
  }, [refetch, userData])
  
  const onEdit = (data) => {
    // console.log('data is..', data);
    activeHorse.current = data;
    setShowEditHorseModal(true);
  };

  const onDelete = (data) => {
    // console.log('data is..', data);
    activeHorse.current = data;
    setArchiveHorseModal(true);
  };

  const getFirstGuardian = (guardians) => {
    let guardianName = '';

    let names = guardians.reduce((acc, curr) => `${acc} ${curr.first_name} ${curr.last_name},` ,'')

    
    if (guardians.length > 0) {
      guardianName = guardians[0].first_name + ' ' + guardians[0].last_name;
    }
    if(guardians.length > 1) guardianName += "..."

    if(names.length > MAX_LENGTH) return names.substring(0, MAX_LENGTH-3) + '...'
    //Clean comma off the end
    if(names.length > 0) names = names.substring(0, names.length-1)
    return names;
  };

  const actions = [];
  if (userData.is_admin || userData.is_barn_manager) {
    actions.push('Add a Horse');
  }

  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) => `${data.name ? data.name : "--"}`,
      display: "Name",
    },
    {
      name: "ownedby",
      default: () => "",
      valFn: (data) => `${data.guardians ? getFirstGuardian(data.guardians) : "--"}`,
      display: "Guardians",
    },
    {
      name: "stallnumber",
      default: () => "",
      valFn: (data) => `${data?.stall_name || "--"}`,
      display: "Assigned Stall",
    },
    {
      name: "notes",
      default: () => "",
      valFn: (data) => `${data.description ? data.description : "--"}`,
      display: "Description",
    },
    {
      name: "actions",
      default: () => "",
      valFn: (data) => {
        let removeBtn = null;
        if (userData.is_admin || userData.is_barn_manager) {
          removeBtn = (
            <ButtonIcon
              icon={<Icon icon="Trash" stroke={null} fill={appColors?.grays?.[510]} />}
              // onClick={() => onDelete(data)}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(data);
              }}
            />
          );
        }

        return (
          <CustomDivTableActions>
            {removeBtn}
            <ButtonIcon
              icon={<Icon icon="ChevronRight" stroke={null} fill={appColors?.grays?.[510]} />}
              onClick={() => onEdit(data)}
            />
          </CustomDivTableActions>
        );
      },
    },
  ];

  const modals = (
    <>
      {archiveHorseModal && (
        <ShowArchiveHorseModal
          setShowModal={setArchiveHorseModal}
          horse={activeHorse.current}
          onSuccess={refetch}
        />
      )}
      {showEditHorseModal && (
        <EditHorseModal
          setShowModal={setShowEditHorseModal}
          horse={activeHorse.current}
          onSuccess={refetch}
        />
      )}
    </>
  );

  return (
    <div css={css}>
      <Toast ref={toastRef} position="top-center" />
      <NavigationActionBar
        defaultTab="Active"
        tabs={horseTabs}
        refetch={refetch}
        actions={actions}
        onActionClick={action => {if(action === 'Add a Horse')activeHorse.current =null; setShowEditHorseModal(action === 'Add a Horse')}}
      />

      <CustomDivTable
        columns={columns}
        rows={data?.data}
        selectionMode="single"
        onSelect={(it) => onEdit(it)}
        filterKeys={["name"]}
        showSearch={false}
      />

      {modals}
    </div>
  );
}