/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { useQuery } from "../../hooks";
import { appColors } from "../../emotion/appColors";

import StallSection from "./stall-section/stall-section";
import StallTray from "./stall-tray";

import { useContext } from "react";
import AuthContext from "../../context/auth-context";
import Icon from "../../components/icon/icon";
import ModalRequestVet from "./components/modal-request-vet";
import NoDataScreen from "../../components/no-data-screen/no-data-screen";

import { css as emotion } from './css'
import css from './stalls.module.css'

function Stalls() {
  const [activeStall, setActiveStall] = useState(null);
  const [activeHorse, setActiveHorse] = useState(null);
  const [showModalRequestVet, setShowModalRequestVet] = useState(false);

  const { data, refetch } = useQuery(`/stalls`);
  
  const {userData} = useContext(AuthContext)

  useEffect(() => {
    //Refresh the activeStall if we got the stall data again
    if(data && activeStall) {
      const newActive = data.data.find((s) => s.id === activeStall.id)
      if(newActive)setActiveStall(newActive)
      else setActiveStall(null)
    }
  }, [data])

    //If user data changes (change barn they are looking at), refresh
    useEffect(() => {
      refetch()
    }, [refetch, userData])
  
  const getContainerStyles = emotion?.container(activeStall);

  const tray = (
    <>
      <div css={emotion.tray.controls.close}>
        <Icon icon='TrayTab' />
      
        <div onClick={() => setActiveStall(null)}>
          <Icon icon='ChevronRight' fill={appColors?.greens?.base} />
        </div>
      </div>

      <div css={emotion.tray} className={activeStall ? 'open' : null}>
        <div css={emotion.tray.x} onClick={() => setActiveStall(null)}>
          <Icon icon='X' fill={appColors?.greens?.base} />
        </div>

        <StallTray
          activeStall={activeStall}
          setActiveStall={setActiveStall}
          refetchStall={refetch}
          setActiveHorse={setActiveHorse}
          activeHorse={activeHorse}
          requestVet={setShowModalRequestVet}
        />
      </div>
    </>
  )

  const handleClose = () => {
    setShowModalRequestVet(false);
  }


  const modals = (
    <>
      <ModalRequestVet
        visible={showModalRequestVet}
        close={()=>handleClose()}
        user={userData}
        horse={activeHorse}
      />
    </>
  )

  const msg = !data?.data ? 'Please select a stable to view stalls.' : 'No stalls found.';

  if (!data?.data || data?.data.length === 0) {
    return (
      <NoDataScreen
        icon='Logo'
        msg={msg}
      />
    );
  }

  return (
    <div
      className={css.stalls}
      css={getContainerStyles}
    >
      <StallSection
        data={data?.data}
        setActiveStall={setActiveStall}
        activeStall={activeStall}
        // className={activeStall ? 'tray-open' : ''}
      />

      {activeStall && tray}
      {/* {tray} */}

      {modals}
    </div>
  )
}

export default Stalls
