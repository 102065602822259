import React from "react";

import css from "./calendar.module.css";
import { Icon } from "../../components";

export default function CalendarTray({ onClick }) {
  const header = (
    <div className={css.header}>
      <h3>Add event</h3>

      <div className={css.close} onClick={() => onClick(false)}>
        <Icon icon="X" />
      </div>
    </div>
  );

  const content = (
    <div className={css.content}>
      <p>tray here</p>
    </div>
  );

  return (
    <div className={css.tray}>
      {header}
      {content}
    </div>
  );
};
